<template>
  <div>
    <div class="main-header">
      <common-header></common-header>
      <div class="second_header">
        <div class="image_box" @click="goIndex()" style="cursor: pointer">
          <img src="../../assets/logo.png" alt="">
        </div>
        <div class="text_box">
          <span v-for="(item,_index) in list_items" :key="_index" :class="{'isChoose':item.key == $route.params.pgId}"
                @click="chooseCom(_index)">{{item.value}}</span>
        </div>
        <div class="tab_login_or_not">
          <span>{{ userName }}</span>
          <span
            v-if="enterpriseState === 'CERTIFIED'"
            style="color: #03FFE4;padding-left: 0;margin-left: 5px"
            >已认证</span
          >
          <span
            v-if="enterpriseState === 'EXAMINING'"
            style="color: #37c5cf; font-weight: bold"
            >待审核</span
          >
          <span
            v-if="enterpriseState === 'UNCERTIFIED'"
            style="color: #ff6a00; font-weight: bold; cursor: pointer"
            @click="goRenzheng()"
            >未认证</span
          >
          <span @click="goIndex()" style="padding: 0 15px;">返回首页</span>
          <span style="padding-right:15px;">|</span>
          <span @click="logOut">退出</span>
        </div>
      </div>
    </div>
    <div class="main_content">
      <component :is="$route.params.pgId"></component>
      <div class="bottom_style" style="width: 1000px;line-height: 26px;text-align: center" v-if="baseUrl.indexOf('minwf.cn') > -1">
        主办单位：宁夏中宁枸杞产业创新研究院、南京中高知识产权股份有限公司、宁夏中宁枸杞产业集团有限公司<br>
        地址：宁夏回族自治区中卫市中宁县科技局<br>
        <a href="https://beian.miit.gov.cn/" target="_blank" style="color:#515a6e">   Copyright©2021 南京中高 All Rights Reserved. 南京中高知识产权股份有限公司 版权所有
        工业和信息化部备案管理系统网站 宁B2-20090191-18</a></div>

      <!-- <div v-else class="bottom_style" style="width: 1000px;line-height: 26px;text-align: center">
        <a style="color: #515a6e" href="https://beian.miit.gov.cn/" target="_blank">连云港科技大市场版权所有2013-2024 苏ICP备18024573号-2. All Rights Reserved.</a>
      </div> -->

    </div>


  </div>
</template>

<script>
  import {getEnterpriseRouteConfig} from "@/plugins/route";
  import {mapGetters, mapMutations} from 'vuex'
  import {warnToast} from "@/plugins/tools/util";
  import axios from "axios";
  import baseUrl from "../../plugins/http/baseUrl";
  import {footInfo2} from "../../plugins/tools/footer";

  export default {
    name: "index",
    computed:{
    ...mapGetters({
        'enterpriseState':'userStore/enterpriseState',
        token: "userStore/token",
        userName: "userStore/userName",
        userId: "userStore/userId",
      })
    },
    mounted(){
      this.baseUrl = baseUrl;
        axios.get(`${baseUrl}/v1/site/one`).then(res => {
          if(res.status == 200){
            let data = res.data.result;
            document.title = data.title;
            this.siteUrl = data.logoImageUrl
            this.siteTitle = data.title
          }
        });
      // if(this.enterpriseState == 'UNCERTIFIED' ||this.enterpriseState == 'EXAMINING' ) {
      //   this.$nextTick(()=> {
      //     this.list_items.splice(1,4);
      //   })
      // }
    },
    components: {
      'science-policy': () => import('@/components/science/SciencePolicyE.vue'),
      'enterprise-school-connection': () => import('@/components/science/EnterpriseSchoolConnection.vue'),
      'enterprise-management-center': () => import('@/components/science/EnterpriseManagementCenter.vue'),
      'enterprise-physical-examination': () => import('@/components/science/EnterprisePhysicalExamination.vue'),
      'enterprise-science-examination': () => import('@/components/science/EnterpriseScienceExamination.vue'),
      'enterprise-soft-examination': () => import('@/components/science/EnterpriseSoftExamination.vue'),
      'patent-trusteeship': () => import('@/components/science/PatentTrusteeship.vue'),
      'collect-list': () => import('@/components/science/collectList.vue'),
      'history': () => import('@/components/science/history.vue'),
      'enterprise-cultivate': () => import('@/components/science/EnterpriseCultivate.vue'),
    },
    data() {
      return {
        baseUrl:'',
        footInfo:"",
        siteUrl:"",
        list_items: baseUrl.indexOf("minwf.cn") > -1? [
          {key: 'enterprise-management-center', value: '企业管理中心'},
          {key: 'enterprise-school-connection', value: '企校通'},
          {key: 'patent-trusteeship', value: '专利托管'},
          {key: 'enterprise-physical-examination', value: '企业体检'},
          {key: 'science-policy', value: '科技政策'}

        ]:[
          {key: 'enterprise-management-center', value: '企业管理中心'},
          {key: 'enterprise-school-connection', value: '供需匹配'},
          {key: 'patent-trusteeship', value: '专利托管'},
          {key: 'enterprise-physical-examination', value: '企业体检'},
          {key: 'collect-list', value: '我的关注'},
          {key: 'history', value: '历史记录'},
          {key: 'enterprise-cultivate', value: '企业培育'}
        ]
      }
    },
    methods: {
      ...mapMutations({
        setUserToken: "userStore/setUserToken",
        setUserName: "userStore/setUserName",
        setUserAccountType: "userStore/setUserAccountType",
        setUserMbrMemberId: "userStore/setUserMbrMemberId",
        setUserId: "userStore/setUserId",
        setUserEnterpriseState: "userStore/setUserEnterpriseState",
      }),
      goIndex() {
        this.$router.push({
          path: '/'
        })
      },
      chooseCom(index) {
        if (index == 4 || index == 5 || index == 6)
          index++
        let nav = getEnterpriseRouteConfig().filter(x => x.navIndex === index);

        if (index-1 == 4 || index-1 == 5){

          this.$router.push({
            path: nav[0].path,
          });
          return;
        }
        if(this.enterpriseState == 'UNCERTIFIED') {
          if(index != 0){
            this.$router.push({path:'/enterprise/EnterpriseCertification'})
            return;
          }
        }
        if(this.enterpriseState == 'EXAMINING' ){
          if(index != 0){
            warnToast("企业认证审核中")
            return;
          }
        }
        if(index !== 2 & index !== 0){
          this.$router.push({
            path: nav[0].path
          });
        }else{
          this.$router.push({
            path: nav[0].path,
            query:{
              id:0,
            }
          });
        }

      },
      logOut() {
        this.setUserToken("");
        this.setUserName("");
        this.setUserAccountType(-1);
        this.setUserMbrMemberId("");
        this.setUserId("");
        this.setUserEnterpriseState("");
        this.$cookies.set("token","",null,null,".lygstrim.com")
        this.$router.push({
          path: "/",
        });
      },
      goRenzheng() {
        this.$router.push({
          path:'/home/EnterpriseCertification'
        });
      },
    }

  }
</script>

<style scoped lang="scss">
  .main_content {
    width: 100%;
    min-width: 1300px;
    height: calc(100% - 101px);
    // margin-top: 101px;
    top: 101px;
    position: relative;
  }
  .main-header {
    position: fixed;
    top: 0;
    width:100%;
    z-index: 100;
  }

  .isChoose {
    background: #1DA3E8;
  }

  .bottom_style {
    position: fixed;
    margin-left: calc(30%);
    //transform: translateX(-50%);
    //left: 55%;
    bottom: 10px;
  }

  .second_header {
    height: 60px;
    width: 100%;
    min-width: 1600px;
    // box-shadow: 0px 6px 12px 0px rgba(220, 220, 220, 0.25);
    background-image: url('../../assets/image/science/banner-bg.png');
    background-size: 100% 100%;
    .image_box {
      height: 50px;
      width:189px;
      margin: 5px 0 5px 10px;
      display: flex;
      align-items: center;
      float: left;
    }
    .text_box {
      float: left;
      margin-left: 63px;
      height: 60px;
      line-height: 60px;
      color: #fff;
    }
  }

  .text_box span {
    display: inline-block;
    margin-right: 40px;
    cursor: pointer;
    font-size: 18px;
    padding: 0 15px;
  }

  .tab_login_or_not {
    padding-right: 31px;
    height: 60px;
    line-height: 60px;
    font-size: 14px;
    float: right;
    color: #fff;
    span {
      display: inline-block;
      cursor: pointer;
    }
  }
</style>
